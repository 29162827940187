import React, { useState, useRef, useEffect } from "react";
import { X } from "lucide-react";
import "./styles.css";

// Utility for validation
const validateFormData = ({ title, url }) => {
  const errors = {};
  if (!title.trim()) errors.title = "Title is required.";
  if (!url.trim()) errors.url = "URL is required.";
  return errors;
};

// Upload Placeholder SVG
const UploadPlaceholder = () => (
  <div className="add-link-upload-placeholder">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
      <polyline points="17 8 12 3 7 8"></polyline>
      <line x1="12" y1="3" x2="12" y2="15"></line>
    </svg>
  </div>
);

const AddLinkModal = ({ isOpen, onClose, onSave }) => {
  const [formData, setFormData] = useState({ title: "", url: "", imageUrl: "" });
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);
  const dialogRef = useRef(null);

  // Handle form field changes
  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    if (errors[field]) setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => handleChange("imageUrl", e.target?.result);
      reader.readAsDataURL(file);
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    onSave(formData);
    setFormData({ title: "", url: "", imageUrl: "" })
    onClose();
  };

  // Open/close modal
  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
      document.body.style.overflow = "hidden";
    } else {
      dialogRef.current?.close();
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const handleCloseDialog = () => {
    setFormData({ title: "", url: "", imageUrl: "" });
    setErrors({});
    onClose();
  }

  return (
    <dialog
      ref={dialogRef}
      className="add-link-dialog"
      aria-labelledby="add-link-title"
      aria-modal="true"
    >
      <div className="add-link-dialog-header">
        <h2 id="add-link-title" className="add-link-dialog-title">
          Add New Link
        </h2>
        <button onClick={handleCloseDialog} className="close-button">
          <X className="add-link-dialog-x" />
        </button>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="add-link-form-group">
          <label htmlFor="image-upload" className="add-link-image-upload-label">
            {formData.imageUrl ? (
              <img src={formData.imageUrl} alt="Uploaded preview" className="add-link-uploaded-image" />
            ) : (
              <UploadPlaceholder />
            )}
          </label>
          <input
            type="file"
            id="image-upload"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/*"
            className="add-link-hidden-input"
          />
        </div>
        <div className="add-link-form-group">
          <label htmlFor="url">URL</label>
          <input
            type="text"
            id="url"
            value={formData.url}
            onChange={(e) => handleChange("url", e.target.value)}
            placeholder="https://example.com"
          />
          {errors.url && <span className="add-link-error">{errors.url}</span>}
        </div>
        <div className="add-link-form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={formData.title}
            onChange={(e) => handleChange("title", e.target.value)}
            placeholder="Enter title of the link"
          />
          {errors.title && <span className="add-link-error">{errors.title}</span>}
        </div>
        <div className="add-link-button-group">
          <button type="button" onClick={handleCloseDialog} className="add-link-cancel-button">
            Cancel
          </button>
          <button type="submit" className="add-link-save-button">
            Save
          </button>
        </div>
      </form>
    </dialog>
  );
};

export default AddLinkModal;
