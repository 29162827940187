import { useRef, useEffect, useState } from "react";
import { X } from "lucide-react";
import "./styles.css";

const AnalyticsDialog = ({ isOpen, onClose }) => {
  const [timeRange, setTimeRange] = useState("7");
  const dialogRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
      document.body.style.overflow = "hidden";
    } else {
      dialogRef.current?.close();
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <dialog ref={dialogRef} className="analytics-dialog">
      <div className="analytics-header">
        <h2>Analytics</h2>
        <button onClick={onClose} className="analytics-dialog-close-button">
          <X className="analytics-dialog-close-icon" size={20} />
        </button>
      </div>

      <div className="time-range-selector">
        <button className={timeRange === "7" ? "active" : ""} onClick={() => setTimeRange("7")}>
          7 Days
        </button>
        <button className={timeRange === "28" ? "active" : ""} onClick={() => setTimeRange("28")}>
          28 Days
        </button>
        <button className={timeRange === "all" ? "active" : ""} onClick={() => setTimeRange("all")}>
          All Time
        </button>
      </div>

      <div className="metrics-container">
        <div className="metric clicks">
          <h3>Total Clicks</h3>
          <div className="value">1234</div>
        </div>
        <div className="metric interactions">
          <h3>Total Interactions</h3>
          <div className="value">5678</div>
        </div>
      </div>

      <div className="chart-container">
        Placeholder for charts
      </div>
    </dialog>
  )
}

export default AnalyticsDialog
