// import Radium from 'radium'
// import { useState } from "react"
// import { Download } from "lucide-react"
// import { Button, Card } from "react-bootstrap"
// const contacts = [
//   {
//     name: "Chinmay Swain",
//     email: "cs@knkt.com",
//     campaign: "Artist Page",
//     phone: "",
//     location: "",
//     lastMessage: "1/23/2025, 3:57 AM",
//     messages: 1,
//   },
//   {
//     name: "vig shetty",
//     email: "vig@knkt.com",
//     campaign: "TikTok",
//     phone: "",
//     location: "",
//     lastMessage: "1/4/2025, 12:13 AM",
//     messages: 1,
//   },
//   {
//     name: "philip brown",
//     email: "phil@knkt.com",
//     campaign: "Instagram",
//     phone: "",
//     location: "",
//     lastMessage: "1/20/2025, 12:13 AM",
//     messages: 1,
//   },
//   {
//     name: "stan han",
//     email: "stan@knkt.com",
//     campaign: "Facebook",
//     phone: "",
//     location: "",
//     lastMessage: "1/25/2025, 12:13 AM",
//     messages: 1,
//   },
//   {
//     name: "karan kumar",
//     email: "karan@knkt.com",
//     campaign: "Instagram",
//     phone: "",
//     location: "",
//     lastMessage: "1/20/2025, 12:13 AM",
//     messages: 1,
//   },
// ]


// const FansPage = () => {
//   const [hoveredRow, setHoveredRow] = useState(null)

//   const handleRowClick = (contact) => {
//     console.log("Row clicked in Contact Table", contact)
//   }

//   return (
//     <div className="min-h-screen bg-black p-6">
//       <Card className="overflow-hidden bg-[#1d1e1e] border-[#8627df] border">
//         <nav className="flex justify-between border-b border-[#8627df]/30 p-4 text-white">
//           <div className="flex gap-6">
//             <Button
//               variant="ghost"
//               className="text-white hover:text-white hover:bg-[#8627df]/20 cursor-pointer px-4 py-2"
//             >
//               CAMPAIGNS
//             </Button>
//             <Button
//               variant="ghost"
//               className="text-white hover:text-white hover:bg-[#8627df]/20 cursor-pointer px-4 py-2"
//             >
//               MY CONTACTS
//             </Button>
//           </div>
//           <Button
//             variant="outline"
//             className="bg-[#fbb655] text-white hover:text-white hover:bg-[#fbb655]/90 border-[#fbb655] cursor-pointer px-4 py-2"
//           >
//             <Download className="mr-2 h-4 w-4" />
//             DOWNLOAD CSV
//           </Button>
//         </nav>
//         <div className="overflow-x-auto mt-5">
//           <div className="w-full">
//             <div className="flex flex-row font-bold bg-[#f2f2f2] mb-2.5">
//               <div className="flex-1 p-3 text-left font-bold w-[150px]">NAME</div>
//               <div className="flex-1 p-3 text-left font-bold w-[150px]">EMAIL</div>
//               <div className="flex-1 p-3 text-left font-bold w-[150px]">CAMPAIGN</div>
//               <div className="flex-1 p-3 text-left font-bold w-[150px] md:hidden lg:block">PHONE</div>
//               <div className="flex-1 p-3 text-left font-bold w-[150px] md:hidden lg:block">LOCATION</div>
//               <div className="flex-1 p-3 text-left font-bold w-[150px] md:hidden lg:block">LAST MESSAGE</div>
//               <div className="flex-1 p-3 text-left font-bold w-[150px] md:hidden lg:block">MESSAGES</div>
//             </div>
//             {contacts.map((contact, index) => (
//               <div
//                 key={index}
//                 onClick={() => handleRowClick(contact)}
//                 onMouseEnter={() => setHoveredRow(index)}
//                 onMouseLeave={() => setHoveredRow(null)}
//                 className={`
//                   flex flex-row justify-between p-1.5 mb-2.5 cursor-pointer
//                   bg-[#1d1e1e] text-[#dcdcdc]
//                   transition-all duration-400 ease-in-out
//                   ${hoveredRow === index ? "bg-[#8627df] shadow-[5px_5px_15px_#8627df,-5px_-5px_15px_#8627df]" : ""}
//                 `}
//               >
//                 <div className="flex-1 p-3 text-left bg-[#444444] w-[100px] break-words">{contact.name}</div>
//                 <div className="flex-1 p-3 text-left bg-[#444444] w-[100px] break-words">{contact.email}</div>
//                 <div className="flex-1 p-3 text-left bg-[#444444] w-[100px] break-words">{contact.campaign}</div>
//                 <div className="flex-1 p-3 text-left bg-[#444444] w-[100px] break-words md:hidden lg:block">
//                   {contact.phone || "-"}
//                 </div>
//                 <div className="flex-1 p-3 text-left bg-[#444444] w-[100px] break-words md:hidden lg:block">
//                   {contact.location || "-"}
//                 </div>
//                 <div className="flex-1 p-3 text-left bg-[#444444] w-[100px] break-words md:hidden lg:block">
//                   {contact.lastMessage}
//                 </div>
//                 <div className="flex-1 p-3 text-left bg-[#444444] w-[100px] break-words md:hidden lg:block">
//                   {contact.messages}
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </Card>
//     </div>
//   )
// }

// export default FansPage;

import { useState } from "react"
import { Download } from "lucide-react"
import { Button, Card } from "react-bootstrap"

const contacts = [
  {
    name: "Chinmay Swain",
    email: "cs@knkt.com",
    campaign: "Artist Page",
    phone: "",
    location: "",
    lastMessage: "1/23/2025, 3:57 AM",
    messages: 1,
  },
  {
    name: "vig shetty",
    email: "vig@knkt.com",
    campaign: "TikTok",
    phone: "",
    location: "",
    lastMessage: "1/4/2025, 12:13 AM",
    messages: 1,
  },
  {
    name: "philip brown",
    email: "phil@knkt.com",
    campaign: "Instagram",
    phone: "",
    location: "",
    lastMessage: "1/20/2025, 12:13 AM",
    messages: 1,
  },
  {
    name: "stan han",
    email: "stan@knkt.com",
    campaign: "Facebook",
    phone: "",
    location: "",
    lastMessage: "1/25/2025, 12:13 AM",
    messages: 1,
  },
  {
    name: "karan kumar",
    email: "karan@knkt.com",
    campaign: "Instagram",
    phone: "",
    location: "",
    lastMessage: "1/20/2025, 12:13 AM",
    messages: 1,
  },
]

const FansPage = () => {
  const [hoveredRow, setHoveredRow] = useState(null)

  const handleRowClick = (contact) => {
    console.log("Row clicked in Contact Table", contact)
  }

  return (
    <div className="min-h-screen bg-black flex justify-center items-start py-4">
      {/* Centered container with max width for a cleaner layout */}
      <div className="w-full max-w-6xl px-4">
        <Card className="overflow-hidden bg-[#1d1e1e] border border-[#8627df] rounded-lg shadow-lg">
          {/* Navbar / Buttons */}
          <nav className="flex justify-between border-b border-[#8627df]/30 p-4 text-white">
            <div className="flex gap-6">
              <Button
                variant="ghost"
                className="text-white hover:text-white hover:bg-[#8627df]/20 cursor-pointer px-4 py-2"
              >
                CAMPAIGNS
              </Button>
              <Button
                variant="ghost"
                className="text-white hover:text-white hover:bg-[#8627df]/20 cursor-pointer px-4 py-2"
              >
                MY CONTACTS
              </Button>
            </div>
            <Button
              variant="outline"
              className="bg-[#fbb655] text-white hover:text-white hover:bg-[#fbb655]/90 border-[#fbb655] cursor-pointer px-4 py-2"
            >
              <Download className="mr-2 h-4 w-4" />
              DOWNLOAD CSV
            </Button>
          </nav>

          {/* Table Container */}
          <div className="overflow-x-auto p-4">
            {/* Table Header */}
            <div className="flex flex-row font-bold bg-[#f2f2f2]">
              <div className="flex-1 p-3 text-left">NAME</div>
              <div className="flex-1 p-3 text-left">EMAIL</div>
              <div className="flex-1 p-3 text-left">CAMPAIGN</div>
              <div className="flex-1 p-3 text-left md:hidden lg:block">
                PHONE
              </div>
              <div className="flex-1 p-3 text-left md:hidden lg:block">
                LOCATION
              </div>
              <div className="flex-1 p-3 text-left md:hidden lg:block">
                LAST MESSAGE
              </div>
              <div className="flex-1 p-3 text-left md:hidden lg:block">
                MESSAGES
              </div>
            </div>

            {/* Table Rows */}
            {contacts.map((contact, index) => (
              <div
                key={index}
                onClick={() => handleRowClick(contact)}
                onMouseEnter={() => setHoveredRow(index)}
                onMouseLeave={() => setHoveredRow(null)}
                className={`
                  flex flex-row text-[#dcdcdc] my-1 cursor-pointer
                  transition-all duration-300 ease-in-out
                  ${hoveredRow === index ? "bg-[#8627df] shadow-[5px_5px_15px_#8627df,-5px_-5px_15px_#8627df]" : "bg-[#2b2b2b]"}
                `}
              >
                <div className="flex-1 p-3 text-left break-words">
                  {contact.name}
                </div>
                <div className="flex-1 p-3 text-left break-words">
                  {contact.email}
                </div>
                <div className="flex-1 p-3 text-left break-words">
                  {contact.campaign}
                </div>
                <div className="flex-1 p-3 text-left break-words md:hidden lg:block">
                  {contact.phone || "-"}
                </div>
                <div className="flex-1 p-3 text-left break-words md:hidden lg:block">
                  {contact.location || "-"}
                </div>
                <div className="flex-1 p-3 text-left break-words md:hidden lg:block">
                  {contact.lastMessage}
                </div>
                <div className="flex-1 p-3 text-left break-words md:hidden lg:block">
                  {contact.messages}
                </div>
              </div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  )
}

export default FansPage
