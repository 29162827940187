import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import CommunityHome from '../components/pages/community/CommunityHome';
import SearchBar from '../components/common/SearchBar';
import ArtistHome from '../components/pages/artists/ArtistHome3';
import FansPage from '../components/pages/community/FansPage';
import LabelsPage from '../components/pages/community/LabelsPage';
import EventOrganizersPage from '../components/pages/community/EventOrganizersPage';
import AgentsPage from '../components/pages/community/AgentsPage';
import MediaPage from '../components/pages/community/MediaPage';
import PublishersPage from '../components/pages/community/PublishersPage';
import VenuesPage from '../components/pages/community/VenuesPage';
import ArtistManagersPage from '../components/pages/community/ArtistManagersPage';
import ProfilePage from '../components/pages/profile/ProfilePage';

const CommunityContainer = (props) => {
  const styles = {
    parentContainer: {
      height: "100%",
      color: "#fff",
      textAlign: "center"
    },
    breadcrumb: {
      margin: '2rem 2rem',
      fontSize: '1rem',
      color: '#ffffff'
    },
    breadcrumbItem: {
      display: 'inline-block',
      marginRight: '0.5rem'
    },
    breadcrumbLink: {
      color: '#ffffff',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    breadcrumbActive: {
      color: '#ffffff',
      textDecoration: 'none',
      cursor: 'default'
    },
    searchBarRow: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      flexGrow: '0',
      flexShrink: '0',
      flexBasis: 'auto',
      width: '100%',
      margin: '1rem 0',
    },
  };

  const [artistProfilesData, setArtistProfilesData] = useState([]);
  const [currentArtistProfile, setCurrentArtistProfile] = useState(null);
  const [activeCommunityPage, setActiveCommunityPage] = useState('CommunityHome');
  const [breadcrumbTrail, setBreadcrumbTrail] = useState([
    { name: 'Community', page: 'CommunityHome' }
  ]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredContent, setFilteredContent] = useState([]);

  // Handle artist selection
  const onSelectArtist = (artist) => {
    setCurrentArtistProfile(artist);
    setActiveCommunityPage('Profile');
    setBreadcrumbTrail([
      { name: 'Community', page: 'CommunityHome' },
      { name: 'Artists', page: 'Artists' },
      { name: artist.name, page: 'Profile' }
    ]);
  };

  // Handle community page navigation
  const onClickCommunityPage = (page) => {
    setActiveCommunityPage(page);
    
    if (page === 'CommunityHome') {
      setBreadcrumbTrail([
        { name: 'Community', page: 'CommunityHome' }
      ]);
    } else {
      setBreadcrumbTrail([
        { name: 'Community', page: 'CommunityHome' },
        { name: page, page: page }
      ]);
    }
  };

  // Handle breadcrumb navigation
  const handleBreadcrumbClick = (index, crumb) => {
    // Don't do anything if clicking the last (current) item
    if (index === breadcrumbTrail.length - 1) return;

    // Navigate to the clicked page
    setActiveCommunityPage(crumb.page);

    // Update breadcrumb trail to include only items up to the clicked item
    setBreadcrumbTrail(breadcrumbTrail.slice(0, index + 1));

    // Clear artist profile if navigating away from profile page
    if (crumb.page !== 'Profile') {
      setCurrentArtistProfile(null);
    }
  };

  // Render breadcrumbs
  const renderBreadcrumbs = () => {
    if (activeCommunityPage === 'CommunityHome') return null;

    return (
      <nav aria-label="breadcrumb" style={styles.breadcrumb}>
        <ol className="breadcrumb">
          {breadcrumbTrail.map((crumb, index) => {
            const isLast = index === breadcrumbTrail.length - 1;
            
            return (
              <React.Fragment key={index}>
                <li 
                  style={{
                    ...styles.breadcrumbItem,
                    ...(isLast ? styles.breadcrumbActive : styles.breadcrumbLink)
                  }}
                  onClick={() => !isLast && handleBreadcrumbClick(index, crumb)}
                >
                  {crumb.name}
                </li>
                {!isLast && <span style={styles.breadcrumbItem}>→</span>}
              </React.Fragment>
            );
          })}
        </ol>
      </nav>
    );
  };

  // Render active page
  const renderActiveCommunityPage = () => {
    switch (activeCommunityPage) {
      case 'Artists':
        return (
          <ArtistHome
            user={props.user}
            filteredContent={filteredContent}
            isFiltered={isFiltered}
            getArtistProfileData={setArtistProfilesData}
            onSelectArtist={onSelectArtist}
          />
        );
      case 'Profile':
        return <ProfilePage artist={currentArtistProfile} />;
      case 'CommunityHome':
        return <CommunityHome onClickCommunityPage={onClickCommunityPage} />;
      case 'Fans':
        return <FansPage />;
        // Add other page cases as needed
      default:
        return <CommunityHome onClickCommunityPage={onClickCommunityPage} />;
    }
  };

  return (
    <div>
      <Row style={styles.searchBarRow}>
        <Col xs="12" style={{ textAlign: 'center' }}>
          <SearchBar
            data={artistProfilesData}
            getResult={setFilteredContent}
            isFiltered={setIsFiltered}
          />
        </Col>
      </Row>
      {renderBreadcrumbs()}
      {renderActiveCommunityPage()}
    </div>
  );
};

export default CommunityContainer;