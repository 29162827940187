import { useState, useRef, useEffect } from "react";
import { X } from 'lucide-react';
import "./styles.css";

const AddTextModal = ({ isOpen, onClose, onSave }) => {
  const [text, setText] = useState("");
  const dialogRef = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
      document.body.style.overflow = "hidden";
      textareaRef.current?.focus();
    } else {
      dialogRef.current?.close();
      document.body.style.overflow = "unset";
    }
  }, [isOpen])

  const handleClose = () => {
    setText("");
    onClose();
  }

  const handleSave = () => {
    if (text.trim()) {
      onSave(text);
      setText("");
      onClose();
    }
  }

  return (
    <dialog ref={dialogRef} className="add-text-dialog">
      <div className="add-text-dialog-header">
        <h2 className="add-text-dialog-title">Add New Text</h2>
        <button onClick={handleClose} className="add-text-dialog-close-button">
          <X className="add-text-dialog-x" />
        </button>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="add-text-form-group">
          <label htmlFor="text">Text</label>
          <textarea
            id="text"
            ref={textareaRef}
            value={text}
            onChange={(e) => setText(e.target.value)}
            rows={6}
            placeholder="Enter your text here..."
          />
        </div>
        <div className="add-text-button-group">
          <button type="button" onClick={handleClose} className="add-text-cancel-button">
            Cancel
          </button>
          <button type="button" onClick={handleSave} className="add-text-save-button" disabled={!text.trim()}>
            Save
          </button>
        </div>
      </form>
    </dialog>
  )
}

export default AddTextModal

